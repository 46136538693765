import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ProfileOutlined, BarsOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import { selectBelongContract, insertGoodsApply } from '@/api/officeApi';
import { selectDutyMember } from '@/api/workOrder';
import moment from 'moment';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
const columns = [{
  title: '序号',
  dataIndex: 'serialNo',
  width: 60,
  slots: {
    customRender: 'serialNo'
  }
}, {
  title: '物品名称',
  dataIndex: 'goodsName',
  slots: {
    customRender: 'goodsName'
  }
}, {
  title: '数量',
  dataIndex: 'goodsNum',
  width: 120,
  slots: {
    customRender: 'goodsNum'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 40,
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    ProfileOutlined,
    BarsOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      itemId: undefined,
      goodsPurpose: "",
      useTime: undefined,
      goodsDetails: [{
        goodsName: undefined,
        goodsNum: undefined
      }]
    });
    const {
      validateTime,
      checkName,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }],
      goodsPurpose: [{
        required: true,
        message: '物品用途不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      useTime: [{
        required: true,
        message: '领用时间不能为空',
        trigger: 'change',
        validator: validateTime
      }]
    };

    const onDelete = key => {
      if (formState.goodsDetails.length == 1) {
        _message.error('物品明细不能为空');

        return;
      }

      formState.goodsDetails.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        // serialNo:undefined,
        goodsName: undefined,
        goodsNum: undefined
      };
      formState.goodsDetails.push(newData);
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        console.log(obj);
        loading.value = true;
        let res = await insertGoodsApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: item.contractName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectDutyMember();

      if (res.code === 200) {
        // console.log(res)
        options.value = res.data; // console.log(options.value)
      }
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 14,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getBelongContract();
      getJobCascade();
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      disabledDate,
      handleAdd,
      onDelete,
      validateTime,
      belongContractOptions,
      options,
      projectOptions,
      columns
    };
  }

});